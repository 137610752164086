import React from "react";

const NavContext = React.createContext<number | undefined>(undefined);
NavContext.displayName = "NavContext";

export function NavProvider({
	children,
	navHeight,
}: {
	children: React.ReactNode;
	navHeight: number;
}) {
	return (
		<NavContext.Provider value={navHeight}>{children}</NavContext.Provider>
	);
}

export function useNavHeight() {
	const context = React.useContext(NavContext);

	if (context === undefined) {
		throw new Error("useNavHeight must be used within a NavProvider");
	}

	return context;
}
